body {
	font-family: 'Open Sans', sans-serif;
}


h1, h2, h3, h4, h5, h6 {
	/*color:#819ab8;*/
}


.logo {
	/* height: 90px; */
	/* width: 400px; */
	margin: 50px;
}

@media only screen and (max-width: 600px) {
	.logo {
		margin: 40px;
		width: 75%;
	}
}

.custom1 {
	/* font-family: 'Yanone Kaffeesatz', sans-serif; */
	font-family: 'Open Sans', sans-serif;
}


.custom2 {
	font-size: 30px !important;
}


.custom3 {}


.h1Class {
	color: #000;
	font-family: 'Open Sans', sans-serif;
}


.h2Class {
	color: #d9e2eb;
}


.h4Class {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #5a5a5a;
}




.register-button {
	border-color: #fff;
	font-size: 25px;
	color: #fff;
	background: linear-gradient(45deg, #0b63a7 0%, #0b63a7 40%, #414042 40%, #414042 100%);
}

.register-button:hover {
	border-color: #fff;
	color: #fff;
	background-color: #414042;
}

.register-button-pricing {
	border-color: rgb(51, 51, 51);
	color: #000;
	font-size: 25px;
}

.register-button-pricing:hover {
	transition-delay: 0.25s;
	background-color: #000;
	border-color: #fff;
	color: #fff;
}



input, textarea, .select {
	background: transparent !important;
	color: #5a5a5a !important;
	border: 1px solid #1b75bc;
}




.navbar-header {
	font-family: 'Open Sans', sans-serif !important;
	color: #fff;
}

.navbar-nav>li.nav-item {
	padding: 0 0.5rem 0 0;
}


.navbar-nav>li.nav-item>a.nav-link {
	color: #fff;
	font-weight: bold;
	/* font-family: 'Open Sans', sans-serif; */
}

.navbar-nav>li.nav-item>a.nav-link:hover {
	color: #fff;
	background-color: #1b75bc;
	border-radius: 0.25rem;

}

.navbar-nav>li.nav-item>a.nav-link:focus {
	color: #fff;
	background-color: #1b75bc;
	border-radius: 0.25rem;

}


.top-offer-bar {
	background: linear-gradient(45deg, #1F4E79 0%, #1F4E79 20%, #414042 20%, #414042 100%);
	color: #fff;
	/* font-family: 'Open Sans', sans-serif; */
}


.top-bar-visit-link {
	margin-right: 5px;
	color: #fff;
	text-decoration: underline;
}

a.top-bar-visit-link:hover {
	color: #1b75bc !important;
	text-decoration: none;
}

.card-header {
	background-color: #1b75bc;
	border-color: rgb(68, 113, 163);
	padding: 7px;
	color: #fff;
	cursor: pointer;
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+/Edge */
	user-select: none;
	/* Standard */
	border-radius: 2rem 2rem 0 0 !important;
}

.card {
	height: 100%;
	background: transparent;
}

.card-header>h4 {
	font-size: 25px;
	font-family: "Times New Roman", Times, serif;
}




.stylish-ul ul>li {
	/* list-style: url(/static/media/checkmark-circle.ec0b2af7.svg); */
	list-style: none;
	line-height: 30px;
	/* font-family: inherit; */
	font-size: 20px;
}

.card-body ul>li {
	/* list-style: url(/static/media/checkmark-circle.ec0b2af7.svg); */
	list-style: none;
	line-height: 30px;
	/* font-family: inherit; */
	font-size: 18px;
}

ul {
	padding: 0;
}

.card-body b {
	font-size: 15px;
}

/*
.card-body:hover{
	background-color: rgb(48, 72, 100);
	border-color: rgb(15, 49, 88);
	color: #fff;
	border-radius: 0 0 2rem 2rem;
}
*/



/********************************contact-us****************************************/

.contact-us-div {
	/*background-image: linear-gradient(180deg, #23262c, #8d1010);
	color: #fff;*/
	/* background-color: #414042; */
	background: #f5f3f3;
	padding: 0px 0 100px 0;
	color: #5a5a5a;
	/* background: rgb(255, 255, 255);
	background: linear-gradient(185deg, rgba(255, 255, 255, 1) 3%, rgba(229, 229, 229, 1) 100%); */

}

.contact-us-submit-button {
	background: #5a5a5a !important;
	border: 1px solid #1b75bc;
	padding: 10px;
	border-radius: 0.5rem;
	color: #fff;
	font-weight: bolder;
	font-size: 17px;
}

.contact-us-submit-button:hover {
	transition-delay: 0.25s;
	background-color: #1b75bc !important;
	color: #fff;
}


.contact-us-email-address {
	color: #646464;
}

.contact-us-email-address:hover {
	color: #1b75bc;
}


.form-control:focus {
	border: 1px solid #1b75bc;
	box-shadow: 0 1px 1px rgba(255, 255, 255, 0.623) inset, 0 0 8px rgba(142, 161, 179, 0.6);
	outline: 0 none;
}




.contact-us-div-1 {
	background-color: #fff;
	margin: 0 0 0 2rem;
	height: 100%;
	padding: 3rem;
	border-radius: 1rem;
	font-size: 20px;
	color: #646464;
}


.contact-us-div-2 {
	background-color: #fff;
	margin: 0 2rem 0 0;
	height: 100%;
	padding: 3rem;
	border-radius: 1rem;
	font-size: 20px;
	color: #646464;
}





@media only screen and (max-width: 600px) {

	.contact-us-div-1 {
		background-color: #fff;
		margin: 0 2rem 0 2rem;
		height: 100%;
		padding: 3rem;
		border-radius: 1rem;
		font-size: 20px;
		color: #646464;
	}


	.contact-us-div-2 {
		background-color: #fff;
		margin: 0 2rem 0 2rem;
		height: 100%;
		padding: 3rem;
		border-radius: 1rem;
		font-size: 20px;
		color: #646464;
	}

}

.radiobuttons {
	font-size: 15px;
}

.contact-us-div-2 h3 {
	font-weight: bold;
}


/* 
.call-us::before {
	display: inline-flex;
	content: "";
	background: url("../../src/images/phone-call-icon.svg");
} */


/******************************pricing**********************************************/

.pricing-div {
	/*background-image: linear-gradient(180deg, #23262c, #3f1f1f);*/
	color: #000;
	padding-top: 150px;
	background: rgb(255, 255, 255);
	background: linear-gradient(5deg, rgba(255, 255, 255, 1) 3%, rgb(238, 238, 238) 100%);
}

.pricing-table {
	line-height: 10px;
	font-size: 14px;
}

.pricing-table>div {
	padding: 10px;
	border: 1px solid #dee2e6 !important;
}


.pricing-amount {
	color: rgb(30, 47, 78);
}

.pricing-plan {
	color: darkgrey;
}



.pricing-plans-div {
	/* border: 1px solid #e0e0e0; */
	background-color: #fff;
	border-radius: 1rem;
}


.pricing-plans-div p {
	color: #999999;
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
}




.pricing-plans-div h3 {
	color: #747474;
}




.pricing-tick {
	--bs-spinner-width: 1.25rem;
	--bs-spinner-height: 1.25rem;
	--bs-spinner-vertical-align: -0.125em;
	background-color: #5a5a5a;
	opacity: 1;
	display: inline-block;
	width: var(--bs-spinner-width);
	height: var(--bs-spinner-height);
	vertical-align: var(--bs-spinner-vertical-align);
	border-radius: 50%;
}


.for-quotation {
	color: #4da3d9;
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	margin: 5px;
	display: contents;
}

.table td {
	padding: 0.5rem;
	font-size: 14px;
}





.mainRow {
	/*background-image: linear-gradient(180deg, #ffffff, #535353, #000);*/
	background-image: url('/src/images/main_row_bg.jpg');
}




.why-edistillery-div {
	background-color: #414042;
	padding: 100px 0 100px 0;
	color: #fff !important;
}

.why-edistillery-div>.card {
	background: transparent !important;
}

.alcohol-book-div {
	margin-top: 150px;
}


.support-div {
	margin-top: 50px;
	background-color: #5a5a5a;
	padding: 100px 0 100px 0;
	color: #fff;
}

.stylish-ul-support ul>li {
	display: inline-flex;
	list-style: none;
	line-height: 40px;
	font-size: 25px;
}

/* 
.stylish-ul-support ul>li::before {
	display: inline-flex;
	flex-shrink: 0;
	width: 1.5em;
	height: 1.5em;
	margin-right: .5rem;
	margin-left: 1.5rem;
	content: "";
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
} */



/***********************************why-edistillery-2**********************************/



.why-edistillery-2 {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
	margin: 50px 50px 0 50px;
}

.why-edistillery-2 .d-col {
	padding: 20px;
	display: flex;
	text-align: left !important;
	font-size: 14px;
}


.why-edistillery-2 .d-col div {
	margin-left: 1rem;
}


.why-edistillery-2 .d-col div h6 {
	font-weight: bold;
}


.why-edistillery-2 .d-col img {
	display: block;
	width: 100px;
	margin-bottom: 2rem;
}



@media only screen and (max-width: 600px) {
	.why-edistillery-2 {
		font-size: 20px;
		font-family: 'Open Sans', sans-serif;
		margin: 50px 25px 0 25px;
	}

}




/*********************************features***************************************/



.features-div {
	/* margin-top: 150px; */
	background: #efefef;
	text-align: center;
	justify-content: center !important;
}



.features-guide-check-out {
	color: #414042 !important;
}

.features-guide-check-out :hover {
	color: #1b75bc !important;
}


.features-cols>ul {
	padding: 10px;
}


.features-cols {
	/*border: rgb(163, 5, 5) solid 1px;*/
	padding: 15px;
}

.features-cols>h2 {
	color: rgb(8, 62, 124);
}



.features-hr {
	border: 0.5px solid #a0a4cf;
	width: 30%;

}




.circle-div {
	padding: 2rem 0 2rem 0;
	height: 100%;
	line-height: 20px;
	border: 1px solid #c2c1c1;
	/* border-top: none; */
	background: #919090;
	color: #fff;
	border-radius: 2rem;
}

.circle-div h5 {
	font-size: 25px;
	/* font-weight: bold; */
}

/* 
.circle-div:hover {
	background: #0b63a7;
	color: #fff;
	cursor: cell;
} */



.circle-div>p {
	margin: 0;
	font-size: 15px;
}


.circle-div div p::before {
	/* color: blue; */
}















.c {
	position: absolute;
	max-width: 500px;
	max-height: 500px;
	width: 350px;
	height: 200px;
	cursor: pointer;
	will-change: transform, opacity;
}

.front,
.back {
	background-size: cover;
}

.back {
	background-image: url(https://images.unsplash.com/photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i&auto=format&fit=crop);
}

.front {
	background-image: url(https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80&auto=format&fit=crop);
}

.containerr {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
}






/******************************why-edistillery2********************************/


.icon-list {
	padding-left: 0;
	list-style: none;
}

.icon-list li {
	display: flex;
	align-items: flex-start;
	margin-bottom: .25rem;
	font-family: 'Open Sans', sans-serif;
	color: #5a5a5a;
	font-weight: 300;
}

.icon-list li span {
	display: contents;
	/* font-size: 15px; */
	font-family: 'Open Sans', sans-serif;
	font-weight: bold;
}

.icon-list li::before {
	display: inline-flex;
	flex-shrink: 0;
	width: 1.5em;
	height: 1.5em;
	margin-right: .5rem;
	content: "";
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}




/***********************************icon-list-dedicated-for**************************************/


.icon-list-dedicated-for {
	padding-left: 0;
	list-style: none;
}

.icon-list-dedicated-for li span {
	display: inline-flex;
	align-items: flex-start;
	margin-bottom: .25rem;
	color: #5a5a5a !important;
	font-size: 20px;
	/* border: 1px solid #83adcf;
	border-radius: 2rem; */
	/* margin-top: 15px; */
	padding: 0.25rem;
	font-family: 'Open Sans', sans-serif;
}



.icon-list-dedicated-for li span::before {
	display: inline-flex;
	flex-shrink: 0;
	width: 1.5em;
	height: 1.5em;
	margin-right: .5rem;
	content: "";
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center / 100% auto;
}



.dedicated-for {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
	margin: 50px 50px 0 50px;
	background-color: #f4f4f4;
	padding: 2rem;
}

.dedicated-for .d-col {
	padding: 20px;
}


.dedicated-for .d-col img {
	width: 125px;
	margin-bottom: 2rem;
	color: #fff;
}



.dedicated-for .d-col p {
	font-weight: bold;
}






@media only screen and (max-width: 600px) {
	.dedicated-for {
		font-size: 20px;
		font-family: 'Open Sans', sans-serif;
		/* margin: 50px 50px 0 50px; */
	}

	.dedicated-for .d-col {
		padding: 20px;
	}


	.dedicated-for .d-col img {
		width: 50%;
		margin-bottom: 2rem;
	}


}





/**************************** the-alcohol-book***************************************/



.overlay-container {
	position: relative;
	/* display: flex; */
}


.overlay-div {
	/*  display: block; */
	z-index: 1;
	width: 100%;
	border: 1px solid #d8d7d7;
	background-color: #d8d7d7;
	padding: 1.5rem;
	border-radius: 1rem;
}


.overlay-center {
	/* position: absolute; */
	/* right: 20%;
	bottom: 20%; */
	/* transform: translate(-50%, 50%); */
	z-index: 2;
	width: 100%;
	background-color: #d8d7d7;
	border: 1px solid #d8d7d7;
	padding: 1.5rem;
	border-radius: 1rem;
}




@media only screen and (max-width: 600px) {
	.overlay-div {
		/*  display: block; */
		z-index: 1;
		width: 90%;
		border: 1px solid #d8d7d7;
		background-color: #d8d7d7;
		padding: 0.5rem;
		border-radius: 1rem;
	}

}